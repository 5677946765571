import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-old-dark-house-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1932 gothic horror, The Old Dark House"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 10</h1>
                    <h2>The Old Dark House</h2>
                    <h3>June 26, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> James Whale // <b>Starring:</b> Boris Karloff &amp; Ernest Thesiger</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/10-The-Old-Dark-House-e2673f6" height="102px" width="450px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 10, The Old Dark House"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-old-dark-house">Read the episode transcript</Link></p>
                        <p>Bring Me The Axe closes out their Pride 2023 series with an enthusiastic love letter to James Whale's 1932 pre-code classic for Universal, The Old Dark House, a movie about class consciousness, secrecy, Lilian Bond's feet, and super gay double entendres. It is a perfectly executed piece of gothic horror starring Boris Karloff that must be seen to be believed.</p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=WqhzhNoTKps" target="_blank" rel="noreferrer">Trailer</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/jaws">Next Episode</Link></li>
                        <li><Link to="/episodes/vamp">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)